import '../styles/FooterStyle.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
    const date = new Date().getFullYear();
    return (
        <div className='footer'>
            <div className='author'>
                Designed & Built with&nbsp;
                <FavoriteIcon className='fav-icon' />
                &nbsp;by&nbsp;<span className='full-name'>Amal Ouassit</span>
            </div>
            <div className='date'>
                Copyright, <span>{date}</span>{' '}
            </div>
            <div className='social-list'>
                <a
                    href='https://github.com/Nozomu-me'
                    style={{ color: 'rgb(142, 106, 203)' }}
                >
                    <GitHubIcon className='social-list-icon' />
                </a>
                <a
                    href='https://ma.linkedin.com/in/amal-ouassit-29529416a'
                    style={{ color: 'rgb(142, 106, 203)' }}
                >
                    <LinkedInIcon className='social-list-icon' />
                </a>
                <a
                    href='https://twitter.com/amalouassit'
                    style={{ color: 'rgb(142, 106, 203)' }}
                >
                    <TwitterIcon className='social-list-icon' />
                </a>
                <span className='line'></span>
            </div>
        </div>
    );
};
export default Footer;
