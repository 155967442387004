import './App.css';
import React from 'react';
import LandingPage from './components/LandingPage';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Header from './components/Header';
import About from './components/About';

function App() {
    return (
        <div className='App'>
            <Header />
            <Navbar />
            <div className='content'>
                <LandingPage />
                {/* <About /> */}
                <Skills />
                <Portfolio />
                <Contact />
            </div>
        </div>
    );
}

export default App;
