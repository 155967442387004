import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EmailIcon from '@mui/icons-material/Email';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import '../styles/NavbarStyle.css';
import Person2Icon from '@mui/icons-material/Person2';

import * as Scroll from 'react-scroll';

const Link = Scroll.Link;
const Navbar = () => {
    return (
        <div className='container' id='container'>
            <nav className='navbar' id='navbar'>
                <Link
                    className='navlink'
                    to='home'
                    activeClass='active'
                    spy={true}
                >
                    <HomeIcon className='nav_icon' />
                    <div id='nav_content'>&nbsp;HOME</div>
                </Link>
                {/* <Link
                    className='navlink'
                    to='about'
                    activeClass='active'
                    spy={true}
                >
                    <Person2Icon className='nav_icon' />
                    <div id='nav_content'>&nbsp;ABOUT</div>
                </Link> */}

                <Link
                    className='navlink'
                    id='nav-skills'
                    to='skills'
                    activeClass='active'
                    spy={true}
                >
                    <EngineeringIcon className='nav_icon' />
                    <div id='nav_content'>SKILLS</div>
                </Link>
                <Link
                    className='navlink'
                    id='nav-portfolio'
                    to='portfolio'
                    activeClass='active'
                    spy={true}
                    offset={-100}
                >
                    <BusinessCenterIcon className='nav_icon' />
                    <div id='nav_content'>PORTFOLIO</div>
                </Link>
                <Link
                    className='navlink'
                    id='nav-contact'
                    to='contact'
                    activeClass='active'
                    spy={true}
                >
                    <EmailIcon className='nav_icon' />
                    <div id='nav_content'>CONTACT</div>
                </Link>
            </nav>
        </div>
    );
};

export default Navbar;
