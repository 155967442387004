import '../styles/HamburgerBarStyle.css';
import Hamburger from 'hamburger-react';
import { useState } from 'react';

const HamburgerBar = () => {
    const [isOpen, setOpen] = useState(false);
    const handClick = () => {
        const nav = document.getElementById('navbar');
        nav.classList.toggle('show');
    };
    return (
        <div>
            <button className='hamburgerBar-btn' onClick={handClick}>
                <Hamburger toggled={isOpen} toggle={setOpen} />
            </button>
        </div>
    );
};

export default HamburgerBar;
