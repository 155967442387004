import { useEffect, useState } from 'react';
import '../styles/SkillsStyle.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { PulseLoader } from 'react-spinners';
import Tooltip from '@mui/material/Tooltip';

import db from '../db.json';
const Skills = () => {
    const [skills, setSkills] = useState([]);
    const [IsLoading, setIsLoading] = useState(true);

    const animation = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.4,
    });
    useEffect(() => {
        setSkills(db.skills);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                transition: {
                    type: ' spring',
                    duration: 0.5,
                    bounce: 0.5,
                },
            });
        }
        if (!inView) {
            animation.start({
                opacity: 0.4,
            });
        }
    }, [inView, animation]);
    if (IsLoading === true)
        return (
            <div>
                <PulseLoader className='loader' color='#8e6acb' />
            </div>
        );

    return (
        <div className='skills-body' id='skills'>
            <motion.div className='skillscontainer'>
                <div className='section-title'>
                    <div className='section-desc'>Skills and Tools</div>
                    <div className='line'></div>
                </div>
                <motion.div ref={ref} animate={animation} className='honeycomb'>
                    {skills.map((skill, index) => (
                        <Tooltip title={skill.title} key={index}>
                            <motion.div
                                className={`hex${skill.id} honeycomb-cell`}
                                key={skill.id}
                            >
                                <img
                                    className='honeycomb-cell_img'
                                    id={`cell_img${skill.id}`}
                                    src={skill.img}
                                    alt={`img${index}`}
                                ></img>
                            </motion.div>
                        </Tooltip>
                    ))}
                </motion.div>
                <div className='notes'>
                    <h1 style={{ color: '#8e6acb', paddingBottom: '10px' }}>
                        Currently working on:
                    </h1>
                    <p style={{}}>
                        Improving my skills by working on an animal encyclopedia
                        using Puppeteer, GraphQl, NodeJs and Reactjs with
                        Typescript.
                    </p>
                </div>
            </motion.div>
        </div>
    );
};

export default Skills;
