import '../styles/LandingPageStyle.css';
import { motion } from 'framer-motion';

const LandingPage = () => {
    return (
        <div className='landing-container' id='home'>
            <motion.div
                className='landing'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className='description'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                    >
                        <h2>
                            Hi<span>,</span> my name is
                        </h2>
                        <h1>
                            Amal
                            <span className='name' data-fill-text='Ouassit'>
                                Ouassit
                            </span>
                        </h1>
                        <h2>I'm a web developer</h2>

                        <a
                            href='https://drive.google.com/file/d/1wRRBHeqAYX2pKUDeSQUi3dmmGw6ERepW/view?usp=sharing'
                            target='_blank'
                            rel='noreferrer'
                            className='resume-btn'
                        >
                            <span>Resume</span>
                            <i></i>
                        </a>
                    </div>
                </div>
                <div className='profile-pic'>
                    <div className='profile-wrapper'>
                        <img
                            className='profile-img'
                            src='/images/pdp.JPG'
                            alt=''
                        />
                        <div className='colorframe'></div>
                        <div className='frame'></div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default LandingPage;
