import '../styles/ContactStyle.css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlaceIcon from '@mui/icons-material/Place';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';
import Footer from './Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Contact = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_3050ncg',
                'template_5u8t4mc',
                e.target,
                'CdnIf2YLcKq5T5cVh'
            )
            .then(
                (result) => {
                    sentNotif();
                },
                (error) => {
                    console.log(error.text);
                }
            );
        setFullName('');
        setEmail('');
        setMessage('');
    };
    const sentNotif = () => {
        toast.success('Message succesfully sent!', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    };
    const theme = createTheme({
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: { color: '#00C9A7' },
                },
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <div className='contact-body' id='contact'>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='contact-container'
                >
                    <div className='contact-form'>
                        <div className='contact-description'>
                            <div className='section-title'>
                                <div className='section-desc'>Get In Touch</div>
                                <div className='line'></div>
                            </div>
                            <p>From more information send an email</p>
                        </div>
                        <div className='contact-card'>
                            <div className='personal-infos'>
                                <h1>Contact Information</h1>
                                <div>
                                    <div className='phone-nbr'>
                                        <PhoneIcon />
                                        <>+212627077249</>
                                    </div>
                                    <div className='email'>
                                        <EmailIcon className='email-icon' />
                                        <>amal.ouassit@gmail.com</>
                                    </div>
                                    <div className='location'>
                                        <PlaceIcon className='location-icon' />
                                        <>Mohammedia, Morocco</>
                                    </div>
                                </div>
                                <div className='social-icons'>
                                    <a
                                        href='https://github.com/Nozomu-me'
                                        style={{ color: 'white' }}
                                    >
                                        <GitHubIcon />
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a
                                        href='https://ma.linkedin.com/in/amal-ouassit'
                                        style={{ color: 'white' }}
                                    >
                                        <LinkedInIcon />
                                    </a>
                                </div>
                            </div>

                            <div className='email-infos'>
                                <form className='form' onSubmit={sendEmail}>
                                    <TextField
                                        required
                                        id='input'
                                        label='Full Name'
                                        sx={{
                                            marginBottom: '20px',
                                            width: '100%',
                                        }}
                                        value={fullName}
                                        onChange={(e) =>
                                            setFullName(e.target.value)
                                        }
                                        name='name'
                                    />
                                    <TextField
                                        required
                                        type='email'
                                        id='input'
                                        label='Email'
                                        sx={{
                                            marginBottom: '20px',
                                            width: '100%',
                                        }}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        name='email'
                                    />
                                    <TextField
                                        id='input'
                                        label='Message'
                                        multiline
                                        rows={8}
                                        sx={{
                                            marginBottom: '20px',
                                            width: '100%',
                                        }}
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        name='message'
                                    />

                                    <Button
                                        type='submit'
                                        className='send-btn'
                                        variant='contained'
                                        endIcon={<SendIcon />}
                                        sx={{
                                            width: '120px',
                                            height: '50px',
                                            backgroundColor: '#00C9A7',
                                        }}
                                    >
                                        Send
                                    </Button>
                                    <ToastContainer
                                        position='top-right'
                                        autoClose={2000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme='light'
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </motion.div>
            </div>
        </ThemeProvider>
    );
};

export default Contact;
