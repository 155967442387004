import '../styles/PortfolioStyle.css';
import GitHub from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { PulseLoader } from 'react-spinners';

import db from '../db.json';

const PortfolioPage = () => {
    const [projects, setProjects] = useState([]);
    const [render, setRender] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setProjects(db.projects);
        setIsLoading(false);
    }, []);

    const handleClick = () => {
        setRender(!render);
    };
    const container = {
        hidden: { opacity: 0, scale: 0.5 },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const item = {
        hidden: { opacity: 0, scale: 0.5 },
        show: { opacity: 1, scale: 1 }
    };
    if (IsLoading === true)
        return (
            <div>
                <PulseLoader className='loader' color='#8e6acb' />
            </div>
        );
    return (
        <div className='portfolio-body' id='portfolio'>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: ' spring', duration: 0.7 }}
                className='porfolio-container'
            >
                <div className='section-title'>
                    <div className='section-desc'>Projects I've worked on</div>
                    <div className='line'></div>
                </div>
                <motion.div className='project-container'>
                    <motion.div
                        initial={{ x: '-100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.5, duration: 0.6 }}
                        className='left'
                    >
                        <img
                            alt={projects[0].name}
                            src={projects[0].projectImg}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ x: '100%', opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ delay: 0.5, duration: 0.6 }}
                        className='right'
                    >
                        <div className='porject-title'>{projects[0].name}</div>

                        <div className='project-desc'>
                            {parse(projects[0].description)}
                        </div>
                        <div className='tec-icons'>
                            {projects[0].technologies.map((tech, index) => (
                                <Tooltip title={tech.title} key={index}>
                                    <img
                                        alt={tech.title}
                                        className='icon'
                                        src={tech.img}
                                    />
                                </Tooltip>
                            ))}
                        </div>
                        <div className='links'>
                            <a href={projects[0].githubLink}>
                                <GitHub className='icon' />
                            </a>
                            <a href={projects[0].link}>
                                <LaunchIcon className='icon' />
                            </a>
                        </div>
                    </motion.div>
                </motion.div>
                <AnimatePresence mode='wait'>
                    {render && (
                        <motion.div
                            variants={container}
                            initial='hidden'
                            animate='show'
                            exit='hidden'
                            className='more-projects'
                            id='more-projects'
                        >
                            {projects.map((project, index) => {
                                if (index > 0)
                                    return (
                                        <motion.div
                                            variants={item}
                                            className='project'
                                            key={index}
                                        >
                                            <div className='icons'>
                                                <FolderOpenIcon className='folder-icon' />
                                                <a href={project.githubLink}>
                                                    <GitHub className='github-icon' />
                                                </a>
                                            </div>
                                            <div className='porject-title'>
                                                {project.name}
                                            </div>
                                            <div className='project-desc'>
                                                {parse(project.description)}
                                            </div>
                                            <div className='tec-icons'>
                                                {project.technologies.map(
                                                    (tech, index) => (
                                                        <Tooltip
                                                            title={tech.title}
                                                            key={index}
                                                        >
                                                            <img
                                                                alt={tech.title}
                                                                className='icon'
                                                                src={tech.img}
                                                            />
                                                        </Tooltip>
                                                    )
                                                )}
                                            </div>
                                        </motion.div>
                                    );
                                return null;
                            })}
                        </motion.div>
                    )}
                </AnimatePresence>
                <Button
                    onClick={handleClick}
                    className='send-btn'
                    variant='contained'
                    sx={{
                        width: '120px',
                        height: '50px',
                        backgroundColor: '#00C9A7'
                    }}
                >
                    {!render ? 'More' : 'Less'}
                </Button>
            </motion.div>
        </div>
    );
};

export default PortfolioPage;
