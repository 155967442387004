import '../styles/HeaderStyle.css';
import HamburgerBar from './HamburgerBar';

const Header = () => {
    return (
        <div className='header'>
            <HamburgerBar />
            <div className='logo'></div>
        </div>
    );
};

export default Header;
